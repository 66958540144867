import {
  Text,
  Container,
  ActionIcon,
  Group,
  rem,
  Box,
  useMantineTheme,
} from "@mantine/core";
import {
  IconBrandYoutube,
  IconBrandInstagram,
  IconBrandLinkedin,
} from "@tabler/icons-react";
import { Logo } from "@/components/Logo";
import Link from "next/link";
import classes from "./index.module.css";
import dayjs from "dayjs";
import { useMediaQuery } from "@mantine/hooks";
import * as m from "@/paraglide/messages";

export function Footer({
  containerSize = "md",
  style = {},
}: {
  containerSize?: "sm" | "md" | "lg" | "xl";
  style?: React.CSSProperties;
}) {
  const data = [
    {
      id: "info",
      title: m.least_gray_stork_cook(),
      links: [
        { label: m.factual_icy_falcon_wish(), link: "/terms" },
        { label: m.antsy_loved_newt_accept(), link: "/support" },
      ],
    },
    {
      id: "community",
      title: m.icy_dull_macaw_build(),
      links: [
        // { label: m.heavy_patient_insect_climb(), link: "#" },
        {
          label: m.lucky_mealy_clownfish_dart(),
          link: "mailto:info@alma-med.com",
        },
        {
          label: m.direct_lofty_lizard_delight(),
          link: "https://www.youtube.com/channel/UC1Nd4zzMTyCK_xcfmkjRjRQ",
        },
      ],
    },
  ];

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}`);

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component={Link}
        // href={link.link}
        href={{
          pathname: link.link,
        }}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.id}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  const mobileLinks = data
    .filter((group) => group.id === "info")
    .map((group) => {
      const links = group.links.map((link, index) => (
        <Text
          key={index}
          className={classes.link}
          component={Link}
          // href={link.link}
          href={{ href: link.link }}
          ta="center"
          fz="12"
        >
          {link.label}
        </Text>
      ));

      return (
        <div className={classes.wrapper} key={group.id}>
          {links}
        </div>
      );
    });

  return (
    <footer className={classes.footer} style={style}>
      <Container size={containerSize} className={classes.inner}>
        <div className={classes.logo}>
          <Logo height={28} width={100} />
          <Text
            display="block"
            size="xs"
            c="dimmed"
            fs="italic"
            className={classes.description}
          >
            ~ {m.curly_seemly_deer_fond()} ~
          </Text>
          <Text
            display="block"
            component={Link}
            href="mailto:info@alma-med.com"
            size="xs"
            c="dimmed"
            className={classes.description}
          >
            info@alma-med.com
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
        <Box hidden={!isMobile}>{mobileLinks}</Box>
      </Container>
      <Container size={containerSize} className={classes.afterFooter}>
        <Text c="dimmed" size="sm">
          © {dayjs().get("year")} alma-med. {m.careful_spry_ibex_embrace()}.
        </Text>

        <Group
          gap={0}
          className={classes.social}
          justify="flex-end"
          wrap="nowrap"
        >
          <ActionIcon
            component={Link}
            target="_blank"
            href="https://www.youtube.com/channel/UC1Nd4zzMTyCK_xcfmkjRjRQ"
            size="lg"
            color="gray"
            variant="subtle"
          >
            <IconBrandYoutube
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
          <ActionIcon
            component={Link}
            target="_blank"
            href="https://www.instagram.com/calc.alma/"
            size="lg"
            color="gray"
            variant="subtle"
          >
            <IconBrandInstagram
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
          <ActionIcon
            component={Link}
            target="_blank"
            href="https://www.linkedin.com/company/alma-med-software/"
            size="lg"
            color="gray"
            variant="subtle"
          >
            <IconBrandLinkedin
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
}
