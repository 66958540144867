/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hello_world = () => `Hello World`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_equal_emu_dig = () => `My Patients`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_tired_baboon_lift = () => `Login`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upper_lost_ray_buy = () => `Register for free`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const acidic_grassy_wombat_quiz = () => `Menu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const extra_stale_panther_pinch = () => `Close Session`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_fair_mare_play = () => `Login`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_grassy_pug_dream = () => `Dynamic monitoring of the`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_deft_toad_hurl = () => `fetal growth and fetal well-being`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_dry_okapi_pick = () => `Monitor the progress of your pregnancy accurately and easily with our tool designed for health professionals`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_fit_mouse_soar = () => `Use Calculator`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mealy_patient_nils_dine = () => `Ultrasound processing and analysis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_aqua_sparrow_drop = () => `ALMA allows you to easily upload data obtained from your patients' ultrasounds, performing an automated and precise analysis, determining the actual gestational age and evaluating Doppler flowmetry.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sweet_witty_skunk_gaze = () => `Avoid mistakes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ok_direct_koala_enjoy = () => `Optimize workflow, reduce manual errors and access detailed real-time reporting that facilitates faster and more effective decision making.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_known_herring_fear = () => `Patient follow-up`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_cuddly_pig_honor = () => `Maintain personalized and dynamic control of each patient, providing continuous monitoring of fetal growth and well-being throughout the pregnancy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_stock_buzzard_grow = () => `Charts and Weight Predictor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_close_chicken_fall = () => `Access graphs that will help you immediately identify whether fetal weight is within normal ranges or if it varies over time. Our fetal weight predictor uses advanced algorithms to project expected growth`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const left_alive_florian_aspire = () => `Simplify prenatal care`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const keen_chunky_mammoth_pout = () => `Avoid making mistakes, with ALMA, you can calculate the real gestational age, monitor fetal weight and evaluate obstetric Doppler flowmetry, all from ultrasound data.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_fancy_mammoth_sing = () => `Does the application have any cost?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_any_stork_zip = () => `At this time, the app is completely free. Our goal is to provide obstetricians with an accessible tool that improves pregnancy monitoring and fetal well-being. Take advantage of all the features at no additional cost.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_polite_niklas_devour = () => `What are the app's calculations based on?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sea_bold_bulldog_work = () => `ALMA calculations are based on international standards established by the Fetal Medicine Foundation (FMF) and the INTERGROWTH-21st project. These protocols ensure that the results conform to the most up-to-date and accurate references for fetal growth monitoring and flowmetry.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_patchy_crossbill_wish = () => `Can I make clinical decisions based solely on the app?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_vexed_jellyfish_stab = () => `No. It is important to remember that this tool is designed as a complementary guide to medical criteria. The results and analysis offered by the calculator should not be used as the sole basis for clinical decision-making or medical conduct, but rather as a support that will help you better interpret the data and accompany the prenatal care process.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_fit_platypus_ripple = () => `What type of patients can I manage in the app?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_strong_kudu_boost = () => `The application is specifically designed for pregnant patients with single (not multiple) pregnancies. You will be able to keep a detailed track of the pregnancy, from the first trimester to delivery, managing all the information derived from ultrasounds and other relevant studies.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_orange_alligator_flop = () => `What information can I enter into the application?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const livid_even_pug_amuse = () => `You will be able to upload ultrasound data for each patient, including fetal biometry, obstetric Doppler flowmetry, and estimated fetal weight. From this data, the application will generate a detailed analysis of fetal growth and overall fetal well-being.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_royal_shad_honor = () => `How can I view my patients' progress?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_sunny_bat_list = () => `By creating an account, the app generates dynamic graphs and clear visualizations of each patient's fetal growth and obstetric Doppler flowmetry, allowing you to monitor fetal development and well-being throughout pregnancy in a visual and understandable way.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gray_north_quail_list = () => `Is the application updated with new standards or improvements?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const acidic_ornate_pony_reap = () => `Yes, we are continuously working on developing new updates that integrate the latest standards and advances in fetal medicine. These updates will be automatically made available to all registered users of the app.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_small_dog_launch = () => `Frequently Asked Questions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_gray_stork_cook = () => `Information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const factual_icy_falcon_wish = () => `Terms`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const antsy_loved_newt_accept = () => `Aid`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const icy_dull_macaw_build = () => `Community`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heavy_patient_insect_climb = () => `Follow us on Twitter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lucky_mealy_clownfish_dart = () => `Contact us`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_lofty_lizard_delight = () => `Tutorials`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_seemly_deer_fond = () => `Data that saves lives, decisions that make a difference`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const careful_spry_ibex_embrace = () => `All rights reserved`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_livid_tern_enrich = () => `Alma | Healthcare Software`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const stale_fair_wren_drip = () => `Gestational Calculator - Track pregnancies more easily, quickly and accurately. Streamline your work and improve the quality of your medical care`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const neat_mild_fly_jolt = () => `Ultrasonography successfully created`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wise_late_salmon_buy = () => `Error creating ultrasound`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_cute_baboon_dart = () => `Gestational Age calculated using Head Circumference. Use LMP and ultrasound date for more accurate calculations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const still_alert_jellyfish_find = () => `Please indicate CC of the study`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const odd_careful_beetle_fulfill = () => `Ultrasound Greater than 14 weeks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loose_fluffy_niklas_persist = () => `Ultrasound Less than 14 weeks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ago_bold_fireant_intend = () => `Clear values`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_quiet_jaguar_surge = () => `Date of Ultrasound`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_misty_okapi_lift = () => `Ultrasound age`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fair_knotty_panther_reside = () => `Less than 14 weeks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_grand_mayfly_trim = () => `Greater than 14 weeks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmpC = () => `cLMP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calcFromHC = () => `Calculated according to Cranial Circumference`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_flaky_walrus_advise = () => `Range`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_lower_wombat_spark = () => `Operator`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_sad_stingray_work = () => `Save`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HC = () => `HC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AC = () => `AC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const FL = () => `FL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EFW = () => `EFW`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CRL = () => `CRL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hc = () => `HC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ac = () => `AC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fl = () => `FL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const efw = () => `EFW`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crl = () => `CRL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grand_busy_insect_swim = () => `Biometrics`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_round_deer_jolt = () => `Peso Fetal Estimado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const leftUterineArteryPulsatilityIndex = () => `Left Uterine Artery Pulsatility Index`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rightUterineArteryPulsatilityIndex = () => `Right Uterine Artery Pulsatility Index`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const umbilicalArteryPulsatilityIndex = () => `Umbilical Artery Pulsatility Index`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const middleCerebralArteryPI = () => `Middle Cerebral Artery Pulsatility Index`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doctusVenosusPI = () => `Ductus Venosus Pulsatility Index`


/**
 * @param {{ weeksNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next_any_orangutan_compose = (params) => `From ${params.weeksNumber} weeks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_still_mare_reap = () => `GA Today`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_alive_camel_drop = () => `Ultrasound GA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EDD = () => `EDD`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HCp = () => `HCc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ACp = () => `ACc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const FLp = () => `FLc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arable_weird_camel_pat = () => `FMF EFWc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_fit_slug_promise = () => `INTERGROWTH-21st`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mealy_strong_crow_link = () => `Autc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next_quiet_tortoise_bubble = () => `UAc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const watery_less_shark_fall = () => `MCAc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_just_wolf_assure = () => `CPRc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_new_ray_foster = () => `DVc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_blue_seal_edit = () => `Gestational Calculator`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const misty_wise_fly_ask = () => `You can use the calculator without registering, thus analyzing each ultrasound scan separately. However, we recommend that you to`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_lime_robin_mend = () => `register`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_lazy_turtle_mend = () => `to be able to follow up with your patients`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const true_quiet_grizzly_enjoy = () => `Alma Medical Software`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loose_steep_herring_renew = () => `Calculator`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_bald_turtle_hike = () => `Ultrasound successfully removed`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_soft_ape_nail = () => `Error: We were unable to delete the ultrasound`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_curly_cockroach_quell = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trick_stout_crow_snip = () => `Birth`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_real_vulture_agree = () => `GA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_ornate_cow_build = () => `EFW`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_direct_snake_roam = () => `EFWc IG-21st`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_elegant_llama_soar = () => `The selected study will be deleted`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cancel = () => `Cancel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const confirm = () => `Confirm`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const strong_lucky_canary_stop = () => `Confirm action`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_salty_canary_attend = () => `Deleting study`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const many_real_antelope_dream = () => `You do not have permission to delete this study`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wide_slow_pelican_read = () => `Son/Daughter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tense_east_llama_pause = () => `The child's name has been successfully updated`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jumpy_maroon_cowfish_adapt = () => `Update child's name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crazy_quaint_gorilla_forgive = () => `Name of child`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const weird_bald_guppy_flip = () => `The patient has been successfully removed from your profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_spare_mink_dazzle = () => `We were unable to remove the patient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_frail_bee_gleam = () => `You want to delete the patient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const careful_level_marmot_imagine = () => `The patient will not be removed from our system. They will be unlinked from their account.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lower_crazy_boar_wish = () => `There was an error updating the pregnancy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const round_jolly_cod_dream = () => `Weeks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_round_jay_link = () => `Birth weight`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_vexed_cheetah_edit = () => `Weight`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const orange_bland_chipmunk_support = () => `INTERGROWTH-21st centile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const silly_good_dove_gleam = () => `Let's get started! Record every ultrasound scan of this pregnancy to ensure accurate and safe obstetric follow-up.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_wild_wolf_pull = () => `EFW Today`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dull_low_bullock_thrive = () => `Pregnancy ended`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const noisy_lazy_quail_buzz = () => `birth GA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_best_lionfish_read = () => `Calculated with`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_least_lobster_radiate = () => `c`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const full_round_pelican_mix = () => `End pregnancy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_steep_flamingo_adapt = () => `Weight predictor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_merry_piranha_dart = () => `Beta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_inclusive_bat_smile = () => `You can select the date to find out the estimated weight and GA for that day, or change the GA or weight to find out the remaining data. Estimated by FMF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const these_lucky_meerkat_nourish = () => `Gestational Age`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_these_husky_aspire = () => `From`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const less_novel_jackal_beam = () => `Pregnancy Updated Successfully`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_silly_cat_launch = () => `Mistake`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bright_pretty_blackbird_renew = () => `Delete Patient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_sleek_canary_boost = () => `You are in the post-term area, you should`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_wild_canary_earn = () => `end the pregnancy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fair_large_seahorse_transform = () => `or`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_heavy_opossum_radiate = () => `remove the patient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_aware_impala_tickle = () => `Ultrasonography`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_civil_pony_work = () => `New Ultrasonography`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_key_fish_burn = () => `Upload your first study to start monitoring this new pregnancy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flaky_civil_nuthatch_tickle = () => `ALMA will automatically select the best ultrasound to date the pregnancy, regardless of when you upload it.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_brief_toucan_engage = () => `Select end date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_dirty_marmot_hack = () => `End date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_candid_skate_sing = () => `Weight chart`


/**
 * @param {{ provider: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mild_sound_carp_peek = (params) => `from ${params.provider}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_best_marten_feel = () => `Percentile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_sad_gadfly_pick = () => `Patient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plane_day_turkey_nurture = () => `Required`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const misty_any_boar_assure = () => `Error creating new patient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_orange_parakeet_propel = () => `Created successfully`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_less_canary_find = () => `Register new patient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_knotty_fly_peel = () => `ID`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pretty_sad_ibex_drop = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mad_vivid_wasp_bask = () => `Last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_yummy_swan_drop = () => `Create`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const caring_game_marten_glow = () => `You are in the post-term area, you should terminate the pregnancy or delete the patient.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zany_last_ostrich_grin = () => `See Patient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const elegant_dull_rat_heal = () => ` Here you will find the list of your patients, sorted by Gestational Age and with a quick view of the estimated delivery date.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_weary_wren_learn = () => `New Patient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_arable_shad_cut = () => `There are no patients matching the search`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const neat_actual_donkey_build = () => `Has no registered patients`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_wild_hawk_grow = () => `Patient schedule according to estimated delivery date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const any_bard_rufian = () => `EDD calendar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_slick_ratin = () => `Login | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ayal_barbose_ratings = () => `Login to Alma Med`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_elegant_oryx_snap = () => `There was an error creating your account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_watery_dove_lock = () => `Verifying email and creating your account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const agent_super_falcon_swim = () => `You forgot to paste your verification code. Please do it and try again.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_basic_turkey_splash = () => `Account created successfully`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lost_major_crossbill_dine = () => `Sign Up | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_crazy_buzzard_offer = () => `Register in Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_spicy_pony_cut = () => `Email verification`


/**
 * @param {{ email: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_calm_kangaroo_persist = (params) => `We have sent a verification email to ${params.email} . Please copy and paste the verification code here.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_strong_snail_trim = (params) => `Welcome to Alma, ${params.name}!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tough_yummy_bumblebee_transform = () => `To complete your registration we need you to provide us with the following information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_grand_stork_ascend = () => `If you are already registered`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const knotty_dirty_dove_love = () => `Sign in`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_keen_fly_fetch = () => `License`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_lazy_lionfish_bask = () => `National`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const novel_free_dragonfly_sail = () => `Provincial`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_ideal_larva_grasp = () => `Registration number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_weary_liger_hike = () => `Province`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const noisy_sleek_ox_hike = () => `Select the province of registration of your professional license`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_steep_midge_clip = () => `Specialty`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_royal_tiger_strive = () => `E-mail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_super_insect_twist = () => `Password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_key_barbel_succeed = () => `I agree with the`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spare_short_worm_swim = () => ` terms and conditions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grand_fluffy_goldfish_devour = () => `Already have an account?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_title = () => `Terms and Conditions | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_header = () => `Legal Notice`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p0 = () => `Alma is a tool designed to facilitate the work of health professionals, specifically in the field of pregnancy monitoring. It is crucial to understand that our application is not designed to make medical decisions on its own.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h1 = () => `Roles and Responsibilities:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p1 = () => `Alma serves as a support tool for physicians, obstetricians, gynecologists, and healthcare professionals involved in prenatal care. The user acknowledges and agrees that the final responsibility for all medical decisions rests with the healthcare professional using the application.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h2 = () => `Assistance, not Substitution`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p2 = () => `Alma is intended to provide information and calculations based on input data. It does not replace clinical judgment, experience, or direct assessment by the healthcare professional. The app should not be used as a substitute for individualized medical evaluation and professional diagnosis.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h3 = () => `Personalized Medical Decisions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p3 = () => `The app provides automated data and calculations, but the interpretation and application of this information should be based on the clinical judgment and experience of the healthcare professional. Each pregnancy is unique, and decisions should be tailored to each patient's specific situation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h4 = () => `User Responsibility`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p4 = () => `The user acknowledges that he or she is responsible for verifying the accuracy of the data entered into the application. Alma assumes no responsibility for decisions made solely based on the information provided by the application. By using Alma, the health professional fully accepts and understands that the application is a support tool intended to improve efficiency and accuracy in making medical decisions, but does not replace the knowledge and judgment of the health professional.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p5 = () => `We appreciate your understanding and commitment to safe and responsible medical practices.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p6 = () => `Sincerely`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p7 = () => `The Alma Team`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_descriptiopn = () => `Terms and conditions Alma Med`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_formal_gopher_aid = () => `Search by name, lastname or ID`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spry_aqua_jackdaw_pave = () => `Calculated with`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_arable_warbler_lift = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const funny_lucky_puffin_aid = () => `Estimated fetal weight (g)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_weird_husky_slurp = () => `Support | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const icy_low_panther_arrive = () => `Alma | Gestational Calculator | Support Page | How can we help you?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mushy_busy_angelfish_play = () => `How can we help you?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const formal_fancy_gazelle_link = () => `Leave us your query and we will answer you as soon as possible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_slow_llama_edit = () => `Send`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_fancy_nils_edit = () => `Invalid email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_clean_capybara_ascend = () => `Don't forget to ask your question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_fun_tuna_praise = () => `We will be in touch shortly.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const early_fine_ape_jest = () => `Query sent`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const even_ok_ox_renew = () => `We were unable to send your query`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmp = () => `LMP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_many_ladybug_kick = () => `Language`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_smug_squirrel_greet = () => `My patients | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_minor_shark_rest = () => `New Patient | Alma`


/**
 * @param {{ patient: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_late_monkey_stop = (params) => `${params.patient} | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legal_suave_panther_sing = () => `Obstetrician`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const honest_mellow_fireant_assure = () => `Sonographer`


/**
 * @param {{ config: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_fluffy_vulture_pop = (params) => `Weight calculated according to the last uploaded ultrasound (${params.config})`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const agent_still_kudu_shine = () => `Weeks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const broad_less_bee_hack = () => `EFWc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_house_worm_bubble = () => `Weight calculation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_factual_hare_amaze = () => `To perform weight calculations in the app you can choose to do so based on FMF or Intergrowth-21st calculations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const steep_suave_shrike_favor = () => `Preferences`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spare_shy_okapi_bump = () => `You cannot modify the test patient. Register and create your patients`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_weary_sparrow_blend = () => `You are viewing a test patient. You will not be able to edit it but you will be able to see the main functionalities.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmpf = () => `LMPf`
