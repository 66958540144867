/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hello_world = () => `Alo MundiÑo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_equal_emu_dig = () => `Meus pacientes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_tired_baboon_lift = () => `Conecte-se`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upper_lost_ray_buy = () => `Cadastre-se gratuitamente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const acidic_grassy_wombat_quiz = () => `Menu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const extra_stale_panther_pinch = () => `Sair`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_fair_mare_play = () => `Conecte-se`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_grassy_pug_dream = () => `Acompanhamento dinâmico de`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_deft_toad_hurl = () => `crescimento e bem-estar fetal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_dry_okapi_pick = () => `Controle a evolução da gravidez de forma precisa e simples com nossa ferramenta voltada para profissionais de saúde`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_fit_mouse_soar = () => `Usar calculadora`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mealy_patient_nils_dine = () => `Processamento e análise de ultrassonografia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_aqua_sparrow_drop = () => `O ALMA permite carregar facilmente os dados obtidos nas ultrassonografias de seus pacientes, realizando uma análise automatizada e precisa, determinando a idade gestacional real e avaliando a fluxometria Doppler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sweet_witty_skunk_gaze = () => `Evite erros`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ok_direct_koala_enjoy = () => `Otimize o fluxo de trabalho, reduza erros manuais e acesse um relatório detalhado em tempo real que facilita a tomada de decisões mais rápida e eficaz`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_known_herring_fear = () => `Monitoramento de pacientes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_cuddly_pig_honor = () => `Manter o controle personalizado e dinâmico de cada paciente, proporcionando monitoramento contínuo do crescimento e bem-estar fetal durante toda a gravidez`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_stock_buzzard_grow = () => `Gráficos e preditor de peso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_close_chicken_fall = () => `Acesse gráficos que ajudarão você a identificar imediatamente se o peso fetal está dentro da normalidade ou se varia ao longo do tempo. Nosso preditor de peso fetal usa algoritmos avançados para projetar o crescimento esperado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const left_alive_florian_aspire = () => `Simplifica o atendimento pré-natal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const keen_chunky_mammoth_pout = () => `Evite cometer erros, com o ALMA você poderá calcular a idade gestacional real, monitorar o peso fetal e avaliar a fluxometria Doppler obstétrica, tudo a partir de dados ultrassonográficos.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_fancy_mammoth_sing = () => `A aplicação tem algum custo?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_any_stork_zip = () => `Neste momento, o aplicativo é totalmente gratuito. Nosso objetivo é disponibilizar aos especialistas em obstetrícia uma ferramenta acessível que melhore o monitoramento da gravidez e o bem-estar fetal. Aproveite todos os recursos sem nenhum custo adicional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_polite_niklas_devour = () => `Em que se baseiam os cálculos do aplicativo?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sea_bold_bulldog_work = () => `Os cálculos feitos pelo ALMA baseiam-se em padrões internacionais estabelecidos pela Fetal Medicine Foundation (FMF) e pelo projeto INTERGROWTH-21st. Esses protocolos garantem que os resultados estejam em conformidade com as referências mais atualizadas e precisas para monitoramento do crescimento fetal e fluxometria.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_patchy_crossbill_wish = () => `Posso tomar decisões clínicas com base apenas no aplicativo?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_vexed_jellyfish_stab = () => `Não. É importante lembrar que esta ferramenta foi concebida como um guia complementar aos critérios médicos. Os resultados e análises oferecidos pela calculadora não devem ser utilizados como única base para a tomada de decisões clínicas ou condutas médicas, mas sim como suporte que a ajudará a interpretar melhor os dados e acompanhar o processo de pré-natal.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_fit_platypus_ripple = () => `Que tipo de pacientes posso gerenciar no aplicativo?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_strong_kudu_boost = () => `O aplicativo foi projetado especificamente para pacientes grávidas com gestações únicas (não múltiplas). Você poderá realizar um acompanhamento detalhado da gravidez, desde o primeiro trimestre até o parto, gerenciando todas as informações derivadas de ultrassonografias e outros estudos relevantes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_orange_alligator_flop = () => `Que informações posso inserir no aplicativo?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const livid_even_pug_amuse = () => `Você poderá fazer upload de dados de ultrassom para cada paciente, incluindo biometria fetal, fluxometria Doppler obstétrica e peso fetal estimado. A partir desses dados, o aplicativo irá gerar uma análise detalhada do crescimento fetal e do bem-estar geral do feto`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_royal_shad_honor = () => `Como posso ver o progresso dos meus pacientes?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_sunny_bat_list = () => `Ao criar uma conta, o aplicativo gera gráficos dinâmicos e visualizações claras do crescimento fetal e da fluxometria Doppler obstétrica de cada paciente, permitindo monitorar o desenvolvimento e o bem-estar do feto durante toda a gravidez de forma visual e compreensível.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gray_north_quail_list = () => `O aplicativo é atualizado com novos padrões ou melhorias?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const acidic_ornate_pony_reap = () => `Sim, trabalhamos continuamente no desenvolvimento de novas atualizações que integrem os mais recentes padrões e avanços em medicina fetal. Essas atualizações serão disponibilizadas automaticamente para todos os usuários cadastrados no aplicativo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_small_dog_launch = () => `Perguntas frequentes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_gray_stork_cook = () => `Informação`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const factual_icy_falcon_wish = () => `Termos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const antsy_loved_newt_accept = () => `Ajuda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const icy_dull_macaw_build = () => `Comunidade`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heavy_patient_insect_climb = () => `Siga-nos no Twitter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lucky_mealy_clownfish_dart = () => `Contate-nos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_lofty_lizard_delight = () => `Tutoriais`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_seemly_deer_fond = () => `Dados que salvam vidas, decisões que fazem a diferença`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const careful_spry_ibex_embrace = () => `Todos os direitos reservados`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_livid_tern_enrich = () => `Alma | Software Médico`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const stale_fair_wren_drip = () => `Calculadora gestacional - Acompanhe as gestações com mais facilidade, rapidez e precisão. Simplifique o seu trabalho e melhore a qualidade do seu atendimento médico`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const neat_mild_fly_jolt = () => `Ultrassom criado com sucesso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wise_late_salmon_buy = () => `Erro ao criar ultrassonografia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_cute_baboon_dart = () => `Idade Gestacional calculada através da Circunferência Craniana. Use cLMP e data de ultrassom para maior precisão nos cálculos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const still_alert_jellyfish_find = () => `Por favor indique CC do estudo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const odd_careful_beetle_fulfill = () => `Ultrassonografia com mais de 14 semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loose_fluffy_niklas_persist = () => `Ultrassonografia com menos de 14 semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ago_bold_fireant_intend = () => `Limpar valores`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_quiet_jaguar_surge = () => `Data do ultrassom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_misty_okapi_lift = () => `Idade do ultrassom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fair_knotty_panther_reside = () => `Menos de 14 semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_grand_mayfly_trim = () => `Maior que 14 semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmpC = () => `DUMc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calcFromHC = () => `Calculado de acordo com a circunferência craniana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_flaky_walrus_advise = () => `Faixa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_lower_wombat_spark = () => `Operador`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_sad_stingray_work = () => `Guardar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HC = () => `CC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AC = () => `CA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const FL = () => `CF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EFW = () => `PEF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CRL = () => `CCN`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hc = () => `CC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ac = () => `CA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fl = () => `CF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const efw = () => `PEF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crl = () => `CCN`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grand_busy_insect_swim = () => `Biometria`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_round_deer_jolt = () => `Peso Fetal Estimado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const leftUterineArteryPulsatilityIndex = () => `IPAut Esquerda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rightUterineArteryPulsatilityIndex = () => `IPAut Direita`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const umbilicalArteryPulsatilityIndex = () => `IP Arteria umbilical`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const middleCerebralArteryPI = () => `IP Artéria Cerebral Média`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doctusVenosusPI = () => `IP Ducto Venoso`


/**
 * @param {{ weeksNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next_any_orangutan_compose = (params) => `A partir de las ${params.weeksNumber} semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_still_mare_reap = () => `IG Hoje`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_alive_camel_drop = () => `IG Ultrassom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EDD = () => `PFP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HCp = () => `CCp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ACp = () => `CAp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const FLp = () => `LFp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arable_weird_camel_pat = () => `PFEp FMF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_fit_slug_promise = () => `INTERGROWTH-21st`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mealy_strong_crow_link = () => `pAut`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next_quiet_tortoise_bubble = () => `pAU`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const watery_less_shark_fall = () => `pACM`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_just_wolf_assure = () => `pRCP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_new_ray_foster = () => `pDV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_blue_seal_edit = () => `Calculadora Gestacional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const misty_wise_fly_ask = () => `Você pode usar a calculadora sem se cadastrar, analisando assim cada ultrassom separadamente. Porém, recomendamos que você`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_lime_robin_mend = () => `se cadastre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_lazy_turtle_mend = () => `para poder acompanhar seus pacientes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const true_quiet_grizzly_enjoy = () => `Software Médico Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loose_steep_herring_renew = () => `Calculadora`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_bald_turtle_hike = () => `Ultrassonografia removida com sucesso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_soft_ape_nail = () => `Erro: não foi possível excluir o ultrassom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_curly_cockroach_quell = () => `Data`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trick_stout_crow_snip = () => `Aniversário`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_real_vulture_agree = () => `IG`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_ornate_cow_build = () => `PEF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_direct_snake_roam = () => `pPEF IG-21st`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_elegant_llama_soar = () => `O estudo selecionado será eliminado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cancel = () => `Cancelar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const confirm = () => `Confirmar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const strong_lucky_canary_stop = () => `Confirmar ação`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_salty_canary_attend = () => `Eliminando o estudo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const many_real_antelope_dream = () => `Você não tem permissão para excluir este estudo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wide_slow_pelican_read = () => `Filho/filha`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tense_east_llama_pause = () => `O nome da criança foi atualizado com sucesso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jumpy_maroon_cowfish_adapt = () => `Atualizar o nome da criança`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crazy_quaint_gorilla_forgive = () => `Nome da criança`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const weird_bald_guppy_flip = () => `O paciente foi removido com sucesso do seu perfil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_spare_mink_dazzle = () => `Não conseguimos eliminar o paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_frail_bee_gleam = () => `Você deseja excluir o paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const careful_level_marmot_imagine = () => `O paciente não será removido do nosso sistema. Você será desvinculado da sua conta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lower_crazy_boar_wish = () => `Ocorreu um erro ao atualizar a gravidez`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const round_jolly_cod_dream = () => `Sem`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_round_jay_link = () => `Peso ao nascer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_vexed_cheetah_edit = () => `Peso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const orange_bland_chipmunk_support = () => `pINTERGROWTH-21st`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const silly_good_dove_gleam = () => `Vamos começar! Registre cada ultrassonografia desta gestação para dar precisão e segurança ao acompanhamento obstétrico`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_wild_wolf_pull = () => `PEF Hoje`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dull_low_bullock_thrive = () => `Terminou a gravidez`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const noisy_lazy_quail_buzz = () => `IG ao nascer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_best_lionfish_read = () => `Calculado com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_least_lobster_radiate = () => `p`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const full_round_pelican_mix = () => `Terminar a gravidez`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_steep_flamingo_adapt = () => `Preditor de peso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_merry_piranha_dart = () => `Beta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_inclusive_bat_smile = () => `Você pode selecionar a data para saber o peso estimado e a IG para aquele dia, ou alterar a IG ou o peso para saber os dados restantes. Caro por FMF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const these_lucky_meerkat_nourish = () => `Idade Gestacional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_these_husky_aspire = () => `De`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const less_novel_jackal_beam = () => `Gravidez atualizada com sucesso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_silly_cat_launch = () => `Erro`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bright_pretty_blackbird_renew = () => `Excluir paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_sleek_canary_boost = () => `Você está na área pós-termo, você deve`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_wild_canary_earn = () => `terminar a gravidez`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fair_large_seahorse_transform = () => `ou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_heavy_opossum_radiate = () => `eliminar o paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_aware_impala_tickle = () => `Ultrassonografia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_civil_pony_work = () => `Nova Ultrassonografia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_key_fish_burn = () => `Carregue seu primeiro estudo para começar a monitorar esta nova gravidez`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flaky_civil_nuthatch_tickle = () => `O ALMA selecionará automaticamente o melhor ultrassom para datar a gravidez, independentemente de quando você o carregar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_brief_toucan_engage = () => `Selecione a data de término`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_dirty_marmot_hack = () => `Data de término`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_candid_skate_sing = () => `Gráfico de peso`


/**
 * @param {{ provider: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mild_sound_carp_peek = (params) => `da ${params.provider}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_best_marten_feel = () => `Percentil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_sad_gadfly_pick = () => `Paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plane_day_turkey_nurture = () => `Obrigatório`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const misty_any_boar_assure = () => `Erro ao criar novo paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_orange_parakeet_propel = () => `Criado com sucesso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_less_canary_find = () => `Cadastrar novo paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_knotty_fly_peel = () => `RG`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pretty_sad_ibex_drop = () => `Nome`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mad_vivid_wasp_bask = () => `Sobrenome`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_yummy_swan_drop = () => `Criar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const caring_game_marten_glow = () => `Você está em uma área pós-termo, deve interromper a gravidez ou eliminar a paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zany_last_ostrich_grin = () => `Ver paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const elegant_dull_rat_heal = () => ` Aqui você encontra a lista de suas pacientes, ordenadas por Idade Gestacional e com uma visualização rápida da provável data do parto`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_weary_wren_learn = () => `Novo paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_arable_shad_cut = () => `Não há pacientes correspondentes à pesquisa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const neat_actual_donkey_build = () => `Não possui pacientes cadastrados`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_wild_hawk_grow = () => `Calendário do paciente de acordo com FPP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const any_bard_rufian = () => `Calendário FPP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_slick_ratin = () => `Entrar | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ayal_barbose_ratings = () => `Entrar na Alma Med`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_elegant_oryx_snap = () => `Ocorreu um erro ao criar sua conta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_watery_dove_lock = () => `Verificando e-mail e criando sua conta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const agent_super_falcon_swim = () => `Você esqueceu de colar seu código de verificação. Faça isso e tente novamente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_basic_turkey_splash = () => `Conta criada com sucesso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lost_major_crossbill_dine = () => `Cadastre-se | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_crazy_buzzard_offer = () => `Cadastre-se em Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_spicy_pony_cut = () => `Verificação de e-mail`


/**
 * @param {{ email: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_calm_kangaroo_persist = (params) => `Enviamos um e-mail de verificação para ${params.email} . Copie e cole o código de verificação aqui.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_strong_snail_trim = (params) => `Bem-vindo à Alma, ${params.name}!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tough_yummy_bumblebee_transform = () => `Para completar seu registro, precisamos que você nos forneça as seguintes informações`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_grand_stork_ascend = () => `Se você já está cadastrado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const knotty_dirty_dove_love = () => `Conecte-se`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_keen_fly_fetch = () => `Mensalidade`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_lazy_lionfish_bask = () => `Nacional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const novel_free_dragonfly_sail = () => `Provincial`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_ideal_larva_grasp = () => `Número da placa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_weary_liger_hike = () => `Província`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const noisy_sleek_ox_hike = () => `Selecione a província de registro do seu registro profissional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_steep_midge_clip = () => `Especialidade`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_royal_tiger_strive = () => `E-mail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_super_insect_twist = () => `Senha`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_key_barbel_succeed = () => `Eu concordo com o`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spare_short_worm_swim = () => ` termos e Condições`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grand_fluffy_goldfish_devour = () => `Você já tem uma conta?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_title = () => `Termos e Condições | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_header = () => `Aviso Legal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p0 = () => `Alma é uma ferramenta desenvolvida para facilitar o trabalho dos profissionais de saúde, especificamente na área de acompanhamento da gravidez. É crucial entender que nosso aplicativo não foi projetado apenas para tomar decisões médicas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h1 = () => `Funções e responsabilidades:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p1 = () => `O Alma serve como ferramenta de apoio para médicos, obstetras e profissionais de saúde envolvidos no pré-natal. O usuário reconhece e concorda que a responsabilidade final por todas as decisões médicas cabe ao profissional de saúde que utiliza o aplicativo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h2 = () => `Assistência, não substituição`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p2 = () => `Alma tem como objetivo fornecer informações e cálculos com base nos dados inseridos. Não substitui o julgamento clínico, a experiência ou a avaliação direta do profissional de saúde. O aplicativo não deve ser utilizado como substituto da avaliação médica individualizada e do diagnóstico profissional.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h3 = () => `Decisões Médicas Personalizadas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p3 = () => `A aplicação fornece dados e cálculos automatizados, mas a interpretação e aplicação desta informação deve basear-se no julgamento clínico e na experiência do profissional de saúde. Cada gravidez é única e as decisões devem ser adaptadas à situação específica de cada paciente.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h4 = () => `Responsabilidade do usuário`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p4 = () => `O usuário reconhece que é responsável por verificar a veracidade dos dados inseridos no aplicativo. A Alma não assume qualquer responsabilidade por decisões tomadas exclusivamente com base nas informações fornecidas pela aplicação. Ao utilizar o Alma, o profissional de saúde concorda e compreende plenamente que o aplicativo é uma ferramenta de apoio que visa melhorar a eficiência e a precisão na tomada de decisões médicas, mas não substitui o conhecimento e julgamento do profissional de saúde.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p5 = () => `Agradecemos sua compreensão e compromisso com práticas médicas seguras e responsáveis.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p6 = () => `Sinceramente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p7 = () => `A equipe da alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_descriptiopn = () => `Termos e condições Alma Med`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_formal_gopher_aid = () => `Pesquise por nome, sobrenome ou ID`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spry_aqua_jackdaw_pave = () => `Calculado com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_arable_warbler_lift = () => `Data`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const funny_lucky_puffin_aid = () => `Peso fetal estimado (g)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_weird_husky_slurp = () => `Suporte | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const icy_low_panther_arrive = () => `Alma | Calculadora Gestacional | Página de suporte | Como podemos ajudá-lo?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mushy_busy_angelfish_play = () => `Como podemos ajudá-lo?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const formal_fancy_gazelle_link = () => `Deixe-nos a sua questão e responderemos o mais breve possível`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_slow_llama_edit = () => `Enviar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_fancy_nils_edit = () => `E-mail inválido`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_clean_capybara_ascend = () => `Não esqueça de fazer sua consulta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_fun_tuna_praise = () => `Entraremos em contato com você em breve`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const early_fine_ape_jest = () => `Consulta enviada`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const even_ok_ox_renew = () => `Não foi possível enviar sua consulta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmp = () => `DUM`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_many_ladybug_kick = () => `Linguagem`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_smug_squirrel_greet = () => `Meus pacientes | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_minor_shark_rest = () => `Novo paciente | Alma`


/**
 * @param {{ patient: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_late_monkey_stop = (params) => `${params.patient} | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legal_suave_panther_sing = () => `Obstetra`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const honest_mellow_fireant_assure = () => `Ultrassonografista`


/**
 * @param {{ config: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_fluffy_vulture_pop = (params) => `Peso calculado com base no último ultrassom enviado (${params.config})`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const agent_still_kudu_shine = () => `Sem`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const broad_less_bee_hack = () => `PEFp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_house_worm_bubble = () => `Cálculo de peso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_factual_hare_amaze = () => `Para realizar cálculos de peso no aplicativo você pode optar por fazê-lo com base nos cálculos FMF ou Intergrowth-21st.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const steep_suave_shrike_favor = () => `Preferências`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spare_shy_okapi_bump = () => `Você não pode modificar o paciente de teste. Cadastre-se e crie seus pacientes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_weary_sparrow_blend = () => `Você está atendendo um paciente de teste. Você não poderá editá-lo, mas poderá ver as principais funcionalidades`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmpf = () => `DUMc`
