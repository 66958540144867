import Head from "next/head";
import { availableLanguageTags } from "@/paraglide/runtime";

type Props = {
  title: string;
  description: string;
  pageSlug: string;
  pageType?: string;
  ogImage?: string;
};

const base = "https://alma-med.com";

// TODO: https://www.npmjs.com/package/next-seo?
export default function SEO({
  title,
  description,
  pageSlug,
  pageType = "website",
  ogImage = `${base}/logos/full-logo-blue.svg`,
}: Props) {
  const ogTitle = title;
  const pageURL = `${base}${pageSlug}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <link rel="canonical" href={pageURL} key="canonical" />
      <meta property="og:type" content={pageType} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={pageURL} />
      <meta property="og:image" content={ogImage} />
      {/* <meta property="og:video" content={`${base}/some-video.mp4`} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={ogImage} />
      <meta name="twitter:url" content={pageURL} />
      <meta name="theme-color" content="#DDA159" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#DDA159" />
      <meta name="robots" content="all" />

      {availableLanguageTags.map((l) => (
        <link
          key={l}
          rel="alternate"
          href={`${base}/${l}${pageSlug}`}
          hrefLang={l}
        />
      ))}
    </Head>
  );
}
